/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.

	function mc_calculate() {

		var result = '';

		var salePrice = Number($('#mc__amount').val());

		var downPayment = Number($('#mc__down').val());
		if (downPayment == 0) {
			downPayment = "0%";
		}

		var options = {
			"salePrice": salePrice,
			"interestRate": Number($('#mc__interest').val()),
			"loanTermMonths": Number($('#mc__period').val() * 12),
			"downPayment": downPayment
		};

		if (downPayment < salePrice) {
			result = MortgageCalculator.calculateMortgage(options);
			$('#mc__payment').text($.number(result.monthlyPayment, 2));
		}

	}

	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages

				if ($('.rellax').length) {
					var rellax = new Rellax('.rellax');
				}

				if ($('.listing__mortgagecalc').length) {
					mc_calculate();
					$('.listing__mortgagecalc input[type="text"]').number( true, 0 );
					$('.listing__mortgagecalc input').on('keyup blur change', mc_calculate);
				}

			},
			finalize: function() {
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
